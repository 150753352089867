<template>
  <footer>
    <div class="main_container pb-50 footerEl">
      <form
        v-if="!subbed"
        @submit.prevent="subscribeMail"
        class="center justify-center py-100 nonwrap gap-20"
        :class="$screen.sm ? 'row' : 'col '"
      >
        <input class="textInput" placeholder="Email" type="text" />
        <button class="buttonInput">SUBSCRIBE</button>
      </form>
      <div v-if="subbed" class="center justify-center py-100 nonwrap gap-20">
        <div class="white--text py-9 font-28">SUBSCRIBED!</div>
      </div>
      <div class="gap" :class="$screen.lg ? 'row' : 'col align-center'">
        <div class="col gap" :class="{ 'align-center': !$screen.lg }">
          <div class="align-center">
            <img src="/icon.png" class="icon" alt="" />
            <img src="/logo.png" class="white_svg" alt="" />
          </div>
          <p :class="{ 'text-center': !$screen.lg }" class="white--text mt-0">
            Made in Monaco, at the heart of the European Union. SecurTeam is
            produced by SecurCom, a privacy-focused pan-European company, and a
            strategic partner of NY-listed EPAM Systems and SecurTech AG of
            Lichtenstein.
          </p>
        </div>
        <div class="gap" :class="$screen.md ? 'row' : 'col'">
          <div class="col" :class="{ 'align-center': !$screen.md }">
            <h3 class="my-0">SecurTeam</h3>
            <p @click="scrollTo('faqEl')">
              <router-link to="">FAQ</router-link>
            </p>
            <p>
              <router-link :to="'/privacy-policy'">Privacy Policy</router-link>
            </p>
            <p><router-link :to="'terms-of-service'">Terms</router-link></p>
          </div>
          <div class="col" :class="{ 'align-center': !$screen.md }">
            <h3 class="my-0">Download</h3>
            <p>
              <a href="https://apps.apple.com/sk/app/securteam/id1488585625"
                >IOS</a
              >
            </p>
            <p>
              <a
                href="https://play.google.com/store/apps/details?id=me.securcom.securchat"
                >Android</a
              >
            </p>
            <p><router-link :to="'/download'">Windows</router-link></p>
            <p><router-link :to="'/download'">Mac OS</router-link></p>
            <p><router-link :to="'/download'">Linux</router-link></p>
          </div>
          <div class="col" :class="{ 'align-center': !$screen.md }">
            <h3 class="my-0">Contact</h3>
            <p>
              <router-link to="/contact">Contact us</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      subbed: false,
    };
  },
  methods: {
    scrollTo(val) {
      if (this.$route.name !== "Home") this.$router.push("/");
      setTimeout(() => {
        let el = document.getElementsByClassName(val)[0];
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 0);
    },
    async subscribeMail() {
      this.subbed = true;
      let url = "https://www.securteam.com/contact.php";
      let res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ email: this.email }),
      });
      res = await res.json();
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #141a25;
}

.textInput {
  appearance: none;
  font-size: 22px;
  color: rgb(102, 102, 102);
  background-color: rgb(255, 255, 255);
  width: 100%;
  font-weight: 600;
  max-width: 400px;
  border-radius: 3px;
  height: 50px;
  border: 3px solid transparent;
  border-radius: 3px;
  padding-left: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  font-family: Arial, Helvetica, sans-serif;
}

.buttonInput {
  font-size: 20px;
  font-weight: 500;
  padding: 0.3em 1em;
  background-color: transparent;
  background-size: cover;
  background-position: 50% center;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  transition: all 0.2s ease 0s;
  line-height: 1.7em !important;
  color: white;
  transition: all 0.3s ease;
}

.textInput:focus-visible {
  border: 3px solid var(--green);
}

.buttonInput:focus-visible {
  border: 2px solid var(--green);
}

.gap {
  gap: 50px;
}

h3 {
  font-size: 28px;
}

img {
  max-width: 250px;
  width: 70%;
  margin-right: 10px;
  object-fit: contain;
}

.icon {
  width: 55px;
}

p {
  margin-bottom: 0;
}

a {
  color: rgb(255, 255, 255);
  transition: all 0.3s ease;
}

a:focus {
  color: var(--green);
  outline: none;
}

.footerEl {
  scroll-margin-top: 80px;
}
</style>