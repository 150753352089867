<template>
  <div class="home">
    <Title />
    <Download />
    <Security />
    <Plan />
    <Faq />
  </div>
</template>

<script>
// @ is an alias to /src
import Download from "@/components/home/Download.vue";
import Faq from "@/components/home/Faq.vue";
import Plan from "@/components/home/Plan.vue";
import Security from "@/components/home/Security.vue";
import Title from "@/components/home/Title.vue";

export default {
  name: "Home",
  components: {
    Download,
    Faq,
    Plan,
    Security,
    Title,
  },
};
</script>
