<template>
  <section class="green text--white py-50 faqEl">
    <h2 class="white--text text-center main_container">Frequently Asked Questions</h2>
    <div class="main_container" :class="$screen.sm ? 'row gap' : 'col'">
      <div class="grow">
        <h3>Is there a maximum number of users?</h3>
        <p>
          You can have an unlimited number of users. If you need more than 1000
          users, contact us at contact@securteam.com and we can offer you a
          customized solution with additional branding and user management
          tools.
        </p>
        <h3>How many people can be in one Audio/Video call?</h3>
        <p>
          There is no technical limit to the number of people in a given audio
          or video conference. Every member of your organization can
          participate!
        </p>
        <h3>How much storage is included?</h3>
        <p>
          There is no limit to the number of messages saved. For file transfer,
          there is a soft limit of 5 GB per user, but we won’t block you if you
          go over and will contact you to adjust your settings accordingly.
        </p>
      </div>
      <div class="grow">
        <h3>Can I customize the branding?</h3>
        <p>
          Of course. You can use your own logo and colors to make SecurTeam your
          own and continue working in a familiar environment.
        </p>
        <h3>Can I get custom Apps for iOS and Android?</h3>
        <p>
          Absolutely! Although this is not included in the standard plan, just
          send us an email at contact@securteam.com and we will let you know the
          price and delivery time for your custom apps.
        </p>
        <h3>How can I stop my subscription?</h3>
        <p>
          You can stop your subscription anytime in your account. Once the
          subscription is cancelled, you will not be charged next month. You
          will continue to have access to subscription expires.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.background {
  background-color: var(--green);
}

p {
  color: var(--white);
}

h3 {
  color: var(--white);
  font-size: 24px;
}

.gap {
  gap: 50px;
}

.faqEl{
  scroll-margin-top: 80px;
}
</style>