<template>
  <section class="main_container py-50 planEl">
    <div class="box col align-center pa-50 text-center">
      <h2 class="mt-0">One plan, everything included</h2>
      <p class="black--text font-28 my-5">14-day Free Trial</p>
      <p class="black--text font-28 my-5">then 3.99€ / month / user *</p>
      <router-link to='/signup' class="btn my-20">GET IT NOW</router-link>
      <p class="black--text font-18 my-5">* instead of 5.99€ / month / user. Promotion ends December 31st.</p>
      <p class="black--text font-18 my-5"> 
        You continue paying the discounted license amount for the lifetime of
        your account.
      </p>
    </div>
    <p class="text-center">
      If you prefer to install SecurTeam directly on your Private Cloud or
      Servers,
    </p>
    <p class="text-center">
      contact us for an instant quote.
      <a href="mailto:contact@securteam.com">contact@securteam.com</a>
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  background-color: rgba(38, 64, 144, 0.09);
  border: 1px solid var(--green);
}

.planEl{
  scroll-margin-top: 80px;
}
</style>