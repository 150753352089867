<template>
  <section class="main_container py-50 securityEl">
    <div :class="$screen.md ? 'row' : 'col'" class="gap-50">
      <div class="col grow">
        <h2>World-leading Security</h2>
        <p>
          SecurTeam is protected with Military-Grade Encryption and advanced key
          management policies.
          <strong>We couldn’t read your data even if we wanted to.</strong>
        </p>
        <p>
          We are an approved epam.com technical partner with active deployment
          in Fortune 500 and numerous SMBs. SecurTeam obtained full HIPAA
          compliance for Healthcare Providers.
        </p>
        <div class="row grow">
          <img src="/img/complient.png" alt="" class="logo" />
          <img class="gdpr" src="/img/GDPR.png" alt="" />
        </div>
      </div>
      <div class="col grow center">
        <img src="/img/meeting.jpg" alt="" class="w100 img" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.logo {
  max-width: 370px;
  width: 70%;
  object-fit: contain;
}

.gdpr {
  max-width: 120px;
  object-fit: contain;
  margin-left: 10px;
  width: 30%;
}

.img {
  max-width: 500px;
  object-fit: contain;
  max-height: 400px;
}
</style>