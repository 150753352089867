<template>
  <div id="app">
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/global/Footer.vue";
import Header from "@/components/global/Header.vue";
export default {
  components: {
    Footer,
    Header
  }
}
</script>

