<template>
  <section class="main_container py-50">
    <div :class="$screen.md ? 'row' : 'col'" class="gap-50">
      <div class="col grow align-center text-center">
        <h2>DOES EVERYTHING</h2>
        <p>Direct Messaging</p>
        <p>Team Channels</p>
        <p>Audio/Video Calls</p>
        <p>Screen Sharing</p>
        <p>File Transfer</p>
        <p>Unlimited Message History</p>
      </div>
      <div class="col grow align-center text-center gap-20">
        <h2>WORKS EVERYWHERE</h2>
        <a
          href="https://play.google.com/store/apps/details?id=me.securcom.securchat"
          class="center"
        >
          <div class="badge">
            <img class="bimg white_" src="/img/play.svg" alt="" />
            <span class="white--text grow font-18 weight-600">GOOGLE PLAY</span>
          </div>
        </a>
        <a
          href="https://apps.apple.com/sk/app/securteam/id1488585625"
          class="center"
        >
          <div class="badge">
            <img class="mimg" src="/img/appstore.svg" alt="" />
            <span class="white--text grow font-18 weight-600">APP STORE</span>
          </div>
        </a>
        <router-link to="/download" class="center">
          <div class="badge col">
            <span class="white--text grow font-12 weight-600"
              >DOWNLOAD FOR</span
            >
            <div class="row center nonwrap ">
              <a
                href="https://apps.apple.com/sk/app/securteam/id1488585625"
                class="center"
              >
              </a>
              <img class="cimg white_svg px-10" src="/img/apple.svg" alt="" />
              <img class="cimg white_svg px-10" src="/img/linux.svg" alt="" />
              <img class="cimg white_svg px-10" src="/img/windows.svg" alt="" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.bimg {
  max-width: 40px;
}
.mimg {
  max-width: 50px;
}
.cimg {
  max-width: 35px;
}

.badge {
  width: 200px;
  height: 66px;
  background-image: linear-gradient(
    to right bottom,
    #409875,
    #349278,
    #2a8d79,
    #20877b,
    #19817b,
    #19817b,
    #19817b,
    #19817b,
    #20877b,
    #2a8d79,
    #349278,
    #409875
  );
  box-shadow: 10px 10px 76px -37px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  background-color: #141a25;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}
</style>