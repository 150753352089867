<template>
  <div>
    <header class="py-20 w100">
      <section class="main_container space-between">
        <router-link class="center" to="/">
          <img src="/icon.png" class="icon" alt="" />
          <img src="/logo.png" alt=""
        /></router-link>
        <div v-if="$screen.md" class="center">
          <div class="center gap-50 green--text weight-600 nonwrap">
            <button @click="scrollTo('homeEl')">HOME</button>
            <button @click="scrollTo('planEl')">PRICING</button>
            <button @click="scrollTo('faqEl')">FAQ</button>
            <router-link to="/contact">CONTACT</router-link>
          </div>
        </div>
        <div v-if="!$screen.md" class="center">
          <button @click="show = !show">
            <svg style="width: 34px; height: 34px" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
              />
            </svg>
          </button>
        </div>
      </section>
    </header>
    <div class="py-45"></div>
    <transition name="slide">
      <div class="col white gap-20 green--text weight-600 menu" v-if="show">
        <button @click="scrollTo('homeEl')">HOME</button>
        <hr />
        <button @click="scrollTo('planEl')">PRICING</button>
        <hr />
        <button @click="scrollTo('faqEl')">FAQ</button>
        <hr />
        <router-link to="/contact">CONTACT</router-link>
        <hr />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    scrollTo(val) {
      if (this.$route.name !== "Home") this.$router.push("/");
      this.show = false;
      setTimeout(() => {
        let el = document.getElementsByClassName(val)[0];
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 0);
    },
  },
  watch: {
    "$screen.width"() {
      if (this.$screen.md) this.show = false;
    },
  },
};
</script>

<style scoped>
header {
  position: fixed;
  border-bottom: 1px solid rgb(232, 232, 232);
  z-index: 2;
  backdrop-filter: blur(10px);
  background-color: rgb(255 255 255 / 75%);
}

img {
  max-width: 200px;
  width: 100%;
  margin-right: 10px;
}

.icon {
  width: 35px;
}

.menu {
  position: fixed;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  z-index: 1;
  top: 80px;
}

.slide-enter {
  transform: translate(0%, -100%);
}
.slide-leave-to {
  transform: translate(0, -100%);
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}

:target {
  scroll-margin-top: 85rem;
}
</style>