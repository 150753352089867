<template>
  <section class="main_container py-50 align-center homeEl">
    <div :class="$screen.md ? 'row' : 'col'" class="gap-50">
      <div class="grow justify-center d-col">
        <h1>Secured Chat and Video for Remote Teams</h1>
        <p>
          Keep your business going with the most complete communication suite.
          Setup Instantly.
        </p>
        <p class="py-20">
          <router-link to="/signup" class="btn">GET IT NOW</router-link>
        </p>
      </div>
      <div class="grow center">
        <img src="/img/group.png" alt="" class="w100 img" />
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.img {
  max-width: 500px;
  object-fit: contain;
}

.homeEl {
  scroll-margin-top: 90px;
}
</style>