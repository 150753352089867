import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScreen from 'vue-screen';

import "@/assets/global.scss";
import "@/assets/helper.scss";
import "@/assets/spacing.scss";

Vue.config.productionTip = false
Vue.use(VueScreen, 'bootstrap'); 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
